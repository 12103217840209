/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
import ESP from 'date-fns/locale/es';
import US from 'date-fns/locale/en-US';

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const pageSize = 20;
export const pageSizes = [10, 20, 30, 50]
export const maxPageSize = 1000;
export const maxFooterPages = 10;

export const GOOGLE_API_KEY = 'AIzaSyCKNxnmW7Afdg28_wqBBuxpYxlKr4o9h4Q'
export const TOKEN_STORAGE_KEY = '__auth'

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;

export const themeRadiusStorageKey = '__theme_radius';
export const REACT_APP_VERSION = '1.0.1';
export const CONFIG_PDF = '__config_pdf';

export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const DEFAULT_USER = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  permiteImpresion: false,
  configuraciones: [],
}

export const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export const DAYS = ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO'];
export const ES_DATE = ESP;
export const US_DATE= US;

export const TARIFAS = [
  { value: 'ESP', label: 'TARIFA ESPECIAL' },
  { value: 'PUB', label: 'PUBLICO EN GENERAL' },
  { value: 'OTR', label: 'OTRO' }
]
